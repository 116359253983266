/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
// import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

// function SEO({ description, lang, meta, title, children }) {
function SEO({
  description,
  lang,
  meta,
  pageUrl,
  title,
  imgUrl,
  type,
  jsonld = null,
}) {
  const data = useStaticQuery(query)
  const defaultTitle = data.seo.top.title

  // Default Website Schema
  let schemaOrgJSONLD = [
    {
      "@context": "https://schema.org",
      "@type": "Corporation",
      knowsLanguage: "JP",
      name: "株式会社フランソア",
      legalName: "株式会社フランソア",
      description: `${data.seo.top.description}`,
      telephone: "092-941-2300",
      address: {
        "@type": "PostalAddress",
        postalCode: "811-0119",
        addressRegion: "福岡県",
        addressLocality: "糟屋郡",
        streetAddress: "新宮町緑ケ浜３−１−１",
        addressCountry: {
          "@type": "Country",
          name: "JP",
        },
      },
      employee: {
        "@type": "Person",
        name: "2391人",
      },
      url: "https://www.francois.co.jp/",
      logo: "https://kurashi.online/logo-francois.png",
    },
  ]
  if (jsonld) {
    // add to default schemaOrgJSONLD variable
    // const addedSchemaOrgJSONLD = [
    //   {
    //     "@context": "http://schema.org",
    //     "@type": "WebSite",
    //     url: site.siteUrl,
    //     name: defaultTitle,
    //   },
    // ]
    if (Array.isArray(jsonld)) {
      schemaOrgJSONLD = [...schemaOrgJSONLD, ...jsonld]
    } else if (typeof jsonld === "object") {
      schemaOrgJSONLD.push(jsonld)
    }
  }

  return (
    <Helmet>
      <html lang="ja" prefix="og:http://ogp.me/n2#/" />
      <title>{title || defaultTitle}</title>
      <meta
        name="description"
        content={description || data.seo.top.description}
      />
      <link rel="canonical" href={pageUrl || data.seo.top.pageUrl} />

      <meta property="og:image" content={imgUrl || "/logo-francois.png"} />
      <meta property="og:site_name" content={defaultTitle} />
      <meta property="og:title" content={title || defaultTitle} />
      <meta property="og:url" content={pageUrl || data.seo.top.pageUrl} />
      <meta
        property="og:description"
        content={description || data.seo.description}
      />
      <meta property="og:type" content={type || "website"} />
      <meta property="og:locale" content="ja_JP" />
      <meta name="twitter:card" content="summary" />

      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>

      {/* OpenGraph  */}
      {/* <meta property="og:title" content="<SOME TEXT HERE>" />
      <meta property="og:site_name" content="<SOME TEXT HERE>" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={process.env.SITE_URL} />
      <meta property="og:description" content="" />
      <meta
        property="og:image"
        content={`${process.env.SITE_URL}/images/gatsby-icon.png`}
      />
      <meta property="fb:app_id" content="<FACEBOOK ID>" /> */}

      {/* Twitter Card */}
      {/* <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={twitter} />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} /> */}
    </Helmet>
  )
}

// SEO.defaultProps = {
//   lang: `ja`,
//   title: "",
//   meta: [],
//   description: ``,
// }

// SEO.propTypes = {
//   description: PropTypes.string,
//   lang: PropTypes.string,
//   meta: PropTypes.arrayOf(PropTypes.object),
//   title: PropTypes.string.isRequired,
// }

export const query = graphql`
  {
    seo: dataYaml {
      top {
        title
        description
        pass
      }
    }
  }
`

export default SEO
