// import libraries
import React from "react"
import styled, { ThemeProvider } from "styled-components"
import "@fontsource/noto-sans-jp/500.css"
import "@fontsource/noto-sans-jp/900.css"
import "@fontsource/rubik/900.css"

// import styles and custom react hooks
import theme from "../styles/theme"
import GlobalStyle from "../styles/globalStyle"

const StyledLayoutWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  font-size: 16px;
  color: #292929;
  /* font-family: "Noto Sans JP:wght@100;300;400;500;700;900"; */

  font-family: "Noto Sans JP";
  white-space: pre-line;
  word-wrap: break-word;
`

const Layout = ({ children, location }) => {
  return (
    <>
      {/* <helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&family=Rubik:wght@300;400;500;600;700;800;900&display=swap"
          rel="stylesheet"
        />
      </helmet> */}

      <StyledLayoutWrapper>
        <GlobalStyle />
        <ThemeProvider theme={theme}>
          {/* <HeaderOverWrappedProvider>
          <Header /> */}

          <main>{children}</main>

          {/* </HeaderOverWrappedProvider> */}
        </ThemeProvider>
      </StyledLayoutWrapper>
    </>
  )
}

export default Layout
