const theme = {
  colors: {
    primary: {
      pink: `#F6BDAE`,
      gray: `#EAEAEA`,
      green: `#B0DCE1`,
      white: `#FFFFFF`,
      brown: `#F2E9D8`,
    },
    shadow: {
      pink: `#F3A793`,
      green: `#8ACBD2`,
      gray: `#D4D4D4`
    },
    dark: {
      gray: `#C4C4C4`,
    },
    font: {
      eng: `Rubik:wght@100;300;400;500;700;900`,
    },
  },
}

export default theme
